
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component
export default class InsightsClusterHotelItem extends Vue {
    @Inject(UserViewServiceS) private userViewService!: UserViewService;
    @Inject(UserServiceS) private userService!: UserService;

    toHotel() {
        this.userViewService.goToHotelPage(this.userService.currentHotelId!);
    }
}
